<template>
     <p>Recetas</p>
  </template>
  
  <script> 
  
  export default {
    components: {
     
    },
  }
  </script>
  